import React from "react"

class ShareBtn extends React.Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }


    handleClick() {
        let t = encodeURI(this.props.post_title) || '';
        let u = encodeURI(window.location.origin+this.props.post_url);
        document.getElementById("gatsby_whatsaap").setAttribute('href', 'https://api.whatsapp.com/send?text='+t+' '+u);
        document.getElementById("gatsby_facebook").setAttribute('href', 'https://www.facebook.com/sharer.php?u='+u);
        document.getElementById("gatsby_twitter").setAttribute('href', 'https://twitter.com/intent/tweet?url='+u+'&text='+t);
        document.getElementById("gatsby_linkedin").setAttribute('href', 'https://www.linkedin.com/shareArticle?url='+u+'&title='+t);
        document.getElementById("gatsby_email").setAttribute('href', 'mailto:?subject=' +t+'&body=Check out this site: '+u+'" title="Share by Email');
    }

    render() {
        let color = this.props.color || 'color-dark';
        return(
            <a href={"#"} data-toggle="modal"  data-target="#shareModal" onClick={this.handleClick} className={color} >
                <i className="fa fa-share-alt" style={{height:'15px'}}  />
            </a>
        )
    }
}

export default ShareBtn
