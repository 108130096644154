import React from "react"
import ReadMoreBtn from "./read-more-btn";
import SectionHeading from "./section-heading";
import {Link} from "gatsby";

class Lac extends React.Component {

    render() {

        return (
            <>
                <div className={"container"}>
                    <div className="row pt-3 py-md-5 mt-md-5">
                        <div className="col-md-4">
                            <div className=" pr-lg-5">
                                <SectionHeading  color={'theme-color-blue'} text={"LEGAL METROLOGY SERVICES"} />
                                <p className="fsm-theme-text mt-2 mb-3 color-normal py-3">
                                    Our regulatory solutions for Food Business Operators and the Indian importers for understanding and overcoming the regulatory obstacles
                                </p>

                                <ReadMoreBtn type={'btn'} text={'Book a consultation'}/>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div>
                                <div className="row">

                                    {this.props.data.map((n2, i) => (
                                        <div className="col-12 col-md-6 col-lg-4" key={i}>
                                            <div className={"hover-changes"}>
                                                <Link to={new URL(n2.node.link).pathname} style={{textDecoration:"none"}}><h5 className={"p-3 fsm-theme-subheading color-normal"}>{n2.node.name}</h5></Link>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Lac
