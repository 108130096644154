import React from "react"
import {Link} from "gatsby";
import ShareBtn from "./share-btn";

const NewsCard = data => {


    function fixLength(str, l){
        if(str === '' || str === undefined || str === null) return '';
        let a = str.substring(0,l).split(' ');
        a.pop();
        return a.join(' ')+'...'
    }

    let c = data.fcolor || 'text-white';
    return (
        <>


            <div className={"card-body pb-0 "+c}>
                <div className="mb-2"><small className="fsm-theme-small-very-text font-bold">{data.data.node.date}</small></div>
                <Link to={new URL(data.data.node.link).pathname} className="text-decoration-none"><h5 className={"card-title fsm-theme-subheading font-800 "+c} style={{lineHeight:'1.1'}} dangerouslySetInnerHTML={{ __html: fixLength(data.data.node.title, 100)}} /></Link>
                <div className="card-text pb-2 pt-1 fsm-theme-small-text" dangerouslySetInnerHTML={{ __html: fixLength(data.data.node.excerpt, 200) }} />
            </div>
            <div className="card-footer bg-transparent border-0">
                <ShareBtn post_title={data.data.node.title} post_url={new URL(data.data.node.link).pathname} color={c}/>
            </div>


        </>
    )
}
export default NewsCard
