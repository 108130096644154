import React from "react"

class SectionMainHeading extends React.Component {

    render() {
        return (
            <>
                <h1 className={"fsm-theme-main-heading "+this.props.color}>{this.props.text}</h1>
            </>
        )
    }
}

export default SectionMainHeading
