import React from "react"
import $ from "jquery"
import RegulatoryUpdateCard from "./regulatory-update-card";
import images from "./images";

class ThemeSlick extends React.Component {
  constructor(props) {
    super(props)
    this.state = { text: "",}
  }
  
  componentDidMount() {
      let p = 0;
    $(".testimonial_dot_container > .testimonial_dot").on('click', function () {
        let e = $(".testimonial_box_container > div");
        p = $(this).index();
        let x = (p * e.first().width());
        e.css('transform','translate('+(-x)+'px)');
        e.removeClass('active');
        e.eq($(this).index()).addClass('active');
        $(this).siblings().removeClass('active');
        $(this).addClass('active');
    });
    $(".slide-right").on('click', function () {
        let e = $(".testimonial_box_container > div");
        if (p < e.length -1) p++;
        let dot = $(".testimonial_dot_container > .testimonial_dot");
        let x = (p * e.first().width());
        e.css('transform','translate('+(-x)+'px)');
        e.removeClass('active');
        e.eq(p).addClass('active');
        dot.eq(p).siblings().removeClass('active');
        dot.eq(p).addClass('active');
    });
    $(".slide-left").on('click', function () {
        let e = $(".testimonial_box_container > div");
        if (p > 0) p--;
        let dot = $(".testimonial_dot_container > .testimonial_dot");
        let x = (p * e.first().width());
        e.css('transform','translate('+(-x)+'px)');
        e.removeClass('active');
        e.eq(p).addClass('active');
        dot.eq(p).siblings().removeClass('active');
        dot.eq(p).addClass('active');
    });
 }

  render() {

      let color = ['rgb(6, 126, 237)','rgb(255, 124, 31)','rgb(14,56,84)'];

      let img = [images.wave, images.halfCircle];


    return (
       <section className="container-fluid px-0 home_testimonial_quotes_list_section home_details_list clini-theme-top-big-gap">
       <div className="row">
           <div className="d-none d-lg-block col-lg-1 text-center"><i className="fa fa-chevron-left slide-left btn" style={{position: 'relative', top: '50%', transform: 'translateY(-50%)'}}> </i></div>
           <div className="col-12 col-lg-10 px-lg-0">

               <div className="testimonial_box_container" style={{boxShadow:'21px 30px 32px 0 rgba(0, 0, 0, 0.04)'}}>

                   {this.props.data.map((node, i) => {
                       if(i > 0){
                           return <RegulatoryUpdateCard data={node} img={img[i%img.length]} color={color[i%color.length]} addToclass={""} key={i}/>
                       }else{
                           return <RegulatoryUpdateCard data={node} img={img[i%2]} color={color[i%color.length]} addToclass={"active"} key={i}/>
                       }

                   })}
                   </div>

           </div>
           <div className="d-none d-lg-block col-lg-1 text-center"> <i className="fa fa-chevron-right slide-right btn" style={{position: 'relative', top: '50%', transform: 'translateY(-50%)'}}> </i></div>
       </div>
       <div className="row mt-3 mt-md-5">
           <div className="col-12 d-flex justify-content-center">
               <div className="testimonial_dot_container pt-4 d-flex mb-4">
               {this.props.data.map((e, i)=> {
                   if(i > 0){
                       return <div className={"testimonial_dot ml-2 "} key={i}/>
                   }else{
                       return <div className={"testimonial_dot ml-2 active"} key={i}/>
                   }
               })}
               </div>
           </div>
       </div>
   </section>

    )
  }
}
export default ThemeSlick
