import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import ReadMoreBtn from "./read-more-btn";
import images from "./images";
import RegulatoryAssociatedFields from "./regulatory-associated-fields";

export default function FeaturedRegulatoryUpdateCard(ruBlogs) {

    let q = useStaticQuery(graphql`
  query {
  allWordpressWpLicensesAndApprovals { 
    edges {
      node {
        name
        link
        wordpress_id
      }
    }
  }
  }`);

    //let cats = q.allWordpressWpLicensesAndApprovals.edges.map(e => e.node.name);

    let blog = ruBlogs.data.filter(e => e.node.licenses_and_approvals !== undefined && e.node.licenses_and_approvals !== null && e.node.licenses_and_approvals.length > 0);

    let bcats = [];
    q.allWordpressWpLicensesAndApprovals.edges.forEach(e => {
        if(blog[0].node.licenses_and_approvals.includes(e.node.wordpress_id)){
            bcats.push(e.node)
        }
    });

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-8  px-3 pr-md-0">
                    <div className="h-100 fsm-one-side-shadow" style={{overflow:'hidden'}}>

                        <div className="card text-white border-0 rounded-0 theme-bg-blue">
                            <div className="position-absolute h-100 w-100" style={{backgroundImage:'url('+images.wave+')', backgroundPosition:'100% 15%', backgroundRepeat:'no-repeat',backgroundSize:'120px auto'}} > </div>
                            <div className="p-4">
                                <div className="row pt-4">
                                    <div className="col-12"><Link to={new URL(bcats[0].link).pathname} className="text-decoration-none"><h5 className="card-title fsm-theme-heading-big color-white">{bcats[0].name}</h5></Link></div>
                                </div>

                                <div className="card-text fsm-theme-small-text color-white-alpha py-3" dangerouslySetInnerHTML={{ __html: blog[0].node.excerpt}} />

                                <RegulatoryAssociatedFields  valueColor={"color-white"} keyColor={"color-white-alpha"} data={blog[0].node.acf} />

                            </div>
                        </div>

                    </div>

                </div>
                <div className="col-12 col-md-4  px-3 pl-md-0">
                    <div className="card border-0 h-100 rounded-0 fsm-one-side-shadow">
                        <div className="card-body">
                            <h5 className="card-title fsm-theme-subheading color-normal font-800">{blog[0].node.title}</h5>
                            <br/>
                            <ReadMoreBtn type={'link'} text={"VIEW MORE"} url={new URL(blog[0].node.link).pathname}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
