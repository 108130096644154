import React from "react"
import NewsCard from "./news-card";

const NewsCardSection = data => {


    let top3 = [];

    for (let i2 = 1; i2 <= 3; i2++) {
        let indv = '';
        let ind = 0;
        for (let i = 0; i < data.data.length; i++) {
            if (indv !== '') {
                if (new Date(data.data[i].node.date) > new Date(indv)) {
                    indv = data.data[i].node.date;
                    ind = i
                }
            } else {
                indv = data.data[i].node.date
            }
        }
        top3.push(data.data[ind]);
        data.data.splice(ind, 1)
    }

    let color = ['rgb(14,56,84)','rgb(255, 124, 31)','rgb(193,230,255)'];
    let fcolor = ['text-white','text-white','color-dark'];
    return (
        <>

            <div className="row py-5">
                <div className="col-12">
                    <div className="card-deck">

                        {top3.filter(e => e !==undefined && e !== null ).map((node, i) => (
                            <div className={"card border-0 col-12 col-md-6 fsm-one-side-shadow rounded-0 mb-5 card-hover-effect "} style={{background:color[i]}} key={i}>
                                <NewsCard data={node} fcolor={fcolor[i%fcolor.length]}/>
                            </div>
                        ))}

                    </div>
                </div>
            </div>

        </>
    )
}
export default NewsCardSection
