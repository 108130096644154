import React from "react"
import SectionHeading from "./section-heading";
import ThemeSlick from "./ThemeSlick";

class RegulatoryUpdateSection extends React.Component {

    render() {
        let blogs = this.props.data.filter((e, i) => i < 3)

        return (

            <div className="container-fluid pb-5" style={{backgroundColor:"#f8f8f8"}}>
                <div className="container py-md-5">

                    <div className="row justify-content-center py-5">
                        <div className="col-12 col-lg-6 text-center">
                            <SectionHeading  color={'theme-color-blue'} text={"Regulatory Updates"} />
                            <br/>
                            <div className="w-lg-50">
                                <small className="fsm-theme-text">
                                    Seeking to attain understanding of the regulatory perspective of the food industry?? Follow our regulatory updates to keep your knowledge refreshed.
                                </small>
                            </div>
                            <br/>
                        </div>
                    </div>

                    <ThemeSlick data={blogs} />

                </div>
            </div>
        )
    }
}

export default RegulatoryUpdateSection
