import React from "react"
import {Link} from "gatsby"
import CategoriesList from "./categories-list";
import ShareBtn from "./share-btn";

const BlogCard = data => {

    return (
        <>
            <div className="card border-0 col-12 col-md-4  mb-5 p-0 m-md-2">
                <div className={"text-white border-0 px-2 pt-2 p-0 m-0 rounded-0"} style={{padding: '5px!important',backgroundColor:data.color,minHeight:'115px'}}>
                    <div className="px-2 px-md-4 py-2">
                        <CategoriesList size={"fsm-theme-extra"} data={{c1:data.data.node.categories.map(e => e.wordpress_id), c2:data.data.node.compliance_services, c3:data.data.node.licenses_and_approvals}}/>
                    </div>
                </div>

                <div className={"card border-0 m-0 rounded-0 px-2 px-md-4 pb-4"} style={{backgroundColor:'#f2f6f9'}}>
                    <div className="card-body px-2">
                        <div className="mb-2"><small className="fsm-theme-small-text">{data.data.node.date}</small></div>
                        <Link to={new URL(data.data.node.link).pathname} style={{textDecoration:'none'}}><h5 className={"color-dark "}  style={{fontSize:'20px',fontFamily:'Muli',fontWeight:'800'}} dangerouslySetInnerHTML={{__html: data.data.node.title}} /></Link>
                        <br/>
                        <br/>
                    </div>
                    <div className="card-footer bg-transparent border-0 px-3">
                        <ShareBtn post_title={data.data.node.title} post_url={new URL(data.data.node.link).pathname} color={''} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default BlogCard
