import React from "react"
import images from "./images";
import SectionHeading from "./section-heading";
import ReadMoreBtn from "./read-more-btn";

class StaticBanner extends React.Component {

    render() {
        return (
            <>
                <div className={"container theme-bg-blue py-3 mt-4"} style={{boxShadow:'21px 30px 32px 0 rgba(0, 0, 0, 0.16)', position:'relative', top:'-65px'}}>
                    <div className="row">
                        <div className="col-12 col-md-3 py-2 py-md-0 text-center">
                            <div className="text-middle">
                                <a href="https://cliniexperts.com/" target='_BLANK'>
                                    <img src={images.cliniExpertsLogo} className="img-fluid" />
                                </a>
                            </div>

                        </div>
                        <div className="col-12  col-md-6 py-2 py-md-0  text-center">
                            <h2 className={"fsm-theme-heading color-white"}>For Regulatory Services</h2>
                        </div>
                        <div className="col-12  col-md-3  py-2 py-md-0  text-center">
                            <div className="text-middle">
                                <ReadMoreBtn type={'btn-trans'} text={'ENQUIRE NOW'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default StaticBanner
