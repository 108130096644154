import React from "react"
import HandpickedBlogCardsList from "./handpicked-blog-cards-list";
import SectionHeading from "./section-heading";

class HandpickedSection extends React.Component {

    render() {

        return (
            <>
                <div className={"container"}>
                    <div className="row justify-content-center py-5">
                        <div className="col-12 col-lg-6 text-center">
                            <SectionHeading  color={'theme-color-blue'} text={"Our Handpicked Blogs"} />
                            <br/>
                            <div className="w-lg-50">
                                <small className="fsm-theme-text color-normal">Glimpse of a few articles to suit your unique requirements.</small>
                            </div>
                            <br/>
                        </div>
                    </div>
                    <HandpickedBlogCardsList data={this.props.data}/>
                </div>
            </>
        )
    }
}

export default HandpickedSection
